<template>
  <footer class="bg-black" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="flex justify-between flex-col md:flex-row">
        <div class="py-4">
          <div class="space-y-8 max-w-md">
            <p class="text-sm leading-6 text-gray-300">
              The Tax Ghost is an Ohio Limited Liability Corporation located at:
              The Charlton Abbott 37903 Euclid Ave. Cleveland, OH 44094-5979
            </p>
          </div>
        </div>
        <div>
          <div
            class="max-w-md flex flex-col items-start md:items-end justify-end h-full py-4"
          >
            <div class="text-sm leading-6 text-gray-300">THE TAX GHOST SM</div>
            <div class="text-sm leading-6 text-gray-300">
              LEAD GENERATION AND REFERRAL SERVICE
            </div>
            <div class="text-sm leading-6 text-gray-300">
              <a href="mailto:info@thetaxghost.com">info@thetaxghost.com</a>
            </div>
            <div class="text-sm leading-6 text-gray-300 underline">
              <a
                target="_blank"
                href="https://72463cbf-ad92-4652-8e42-cba7d8b07d3d.filesusr.com/ugd/01d798_9095f739417843f3a06e087e2b66ad48.pdf"
                >Terms & Privacy</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
        <p class="text-xs leading-5 text-gray-400">
          &copy; {{ currentYear }} The Tax Ghost LLC. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
const currentYear = new Date().getFullYear();
</script>
